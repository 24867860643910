<template>
    <amp-module-header
        :amp-id="announcementId"
        @ready="onModuleReady"
        @publish="publish"
    >
        <template #default="{ create, reload, setPrePublishHook }">
            <v-container
                v-if="canAccessAmp"
                :class="{
                    'pt-10 pb-6': $vuetify.breakpoint.mdAndUp,
                    'px-0': $vuetify.breakpoint.smAndDown
                }"
            >
                <v-row>
                    <author-amp-banner v-if="isAuthoredAmp" />
                    <v-col md="8">
                        <v-tabs
                            background-color="main-background"
                            slider-size="4"
                            class="module-tabs"
                            show-arrows
                            fixed-tabs
                            centered
                        >
                            <v-tab
                                v-for="tab in tabsAdjusted"
                                :key="tab.label"
                                :to="fullify(tab.to)"
                                :disabled="tab.disabled"
                                class="text-capitalize module-tab px-2"
                            >
                                <v-icon small left>{{ tab.icon }}</v-icon>
                                {{ tab.label }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col
                        md="4"
                        class="d-flex align-center"
                        :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }"
                    >
                        <amp-module-link
                            :links="moduleLinks"
                            :warn="hasChecksum"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <!--
                Here we go down the route
                AmpModuleHeader subscribes to events every module can emit
            -->
            <router-view
                v-if="canAccessAmp"
                @create="create"
                @reload="reload"
                @pre-publish="setPrePublishHook"
                @links="setModuleLinks"
            />
            <v-container v-else class="pa-0 pt-4">
                <v-row>
                    <v-col class="mx-n1">
                        <v-card-title>
                            <v-skeleton-loader
                                loading
                                type="heading"
                                width="75%"
                            />
                        </v-card-title>
                        <v-card-text>
                            <v-skeleton-loader loading type="paragraph" />
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </amp-module-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { AmpModuleHeader, AmpModuleLink } from '@/components/AmpModule';
import { AuthorAmpBanner } from '@/components/Announcements/AuthorAmpBanner';

import type { Announcement, AmpModules } from '@/types/Announcement';
import type { NavigationGuardNext, Route } from 'vue-router';
import type { ModuleLink } from '@/types/ModuleLink';

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteLeave']);

type AnnouncementReady = {
    announcement: Partial<Announcement>;
    modules: AmpModules;
};

@Component({
    components: {
        AmpModuleHeader,
        AuthorAmpBanner,
        AmpModuleLink
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor', 'isClient'])
    }
})
export default class Container extends Vue {
    isAuthor!: boolean;
    isEditor!: boolean;
    isClient!: boolean;

    announcement: Partial<Announcement> = {};

    moduleLinks: ModuleLink[] = [];

    get tabs() {
        return [
            {
                label: 'News Article',
                icon: 'file-alt',
                to: 'article'
            },
            {
                label: 'Blog Post',
                icon: 'pen-square',
                to: 'blog-post',
                disabled: this.isLimitedDistribution
            },
            {
                label: 'Podcast',
                icon: 'headphones',
                to: 'podcast',
                disabled: this.isLimitedDistribution
            },
            {
                label: 'Slideshow',
                icon: 'tv',
                to: 'slideshow',
                disabled: this.isLimitedDistribution
            },
            {
                label: 'Infographic',
                icon: 'file-image',
                to: 'infographic',
                disabled: this.isLimitedDistribution
            },
            {
                label: 'Video',
                icon: 'play-circle',
                to: 'video',
                disabled: this.isLimitedDistribution
            }
        ];
    }

    get tabsAdjusted() {
        if (this.isClient && this.isDFY) {
            const ordered = [...this.tabs];
            // put BlogPost first
            [ordered[0], ordered[1]] = [ordered[1], ordered[0]];

            return ordered;
        }

        return this.tabs;
    }

    get validRoutes() {
        return this.tabs.filter(tab => !tab.disabled).map(tab => tab.to);
    }

    get allowedRoute() {
        return this.validRoutes[0];
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get isLimitedDistribution() {
        return this.announcement.press_release?.limited_distribution_only;
    }

    get isAmpReady() {
        return Boolean(Object.keys(this.announcement).length);
    }

    get canAccessAmp() {
        if (this.isAmpReady) {
            return this.announcement.is_in_author_process
                ? this.isEditor || this.isAuthor
                : true;
        }

        return false;
    }

    get isAuthoredAmp() {
        return (
            this.hadAssignment &&
            this.announcement.status_string !== 'Published' &&
            !this.isAuthor &&
            !this.isEditor
        );
    }

    get hadAssignment() {
        const rejectedTime =
            this.announcement.author_request_assignment?.author_request
                ?.rejected_time;

        return this.announcement.author_request_assignment && !rejectedTime;
    }

    get isDFY() {
        return Boolean(
            this.announcement.author_request_assignment?.author_request.user_id
        );
    }

    get hasChecksum() {
        return Boolean(this.announcement.checksum);
    }

    onModuleReady({ announcement, modules }: AnnouncementReady) {
        this.announcement = announcement;

        this.protectRoute(modules);
    }

    beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
        this.setModuleLinks();

        if (to.params?.module) {
            if (!this.validRoutes.includes(to.params?.module)) {
                next(this.allowedRoute);
            } else {
                next();
            }
        }
    }

    protectRoute(modules: AmpModules) {
        if (!this.canAccessAmp) {
            // amp is still in process, regular users cannot access it yet
            return this.$router.replace('/announcements');
        }

        if (!this.$route.params.module) {
            if (this.isClient && this.isDFY && !this.isLimitedDistribution) {
                // Clients default to Blog Post, rest to News Article
                // But, if Amp was already authored, check if BP was actually created
                if (this.isAuthoredAmp ? modules.blog_post_id : true) {
                    return this.$router.replace(
                        `${this.$route.path}/blog-post`
                    );
                }
            }

            return this.$router.replace(`${this.$route.path}/article`);
        }

        if (!this.validRoutes.includes(this.$route.params.module)) {
            this.$router.replace(this.allowedRoute);
        }

        if (
            this.$route.params.mode === 'portrait' &&
            !modules.portrait_video_pr_id
        ) {
            if (this.validRoutes.includes('video')) {
                this.$router.replace('video');
            } else {
                this.$router.replace(this.allowedRoute);
            }
        }
    }

    async publish() {
        return this.$router.push(
            `/announcements/publish/${this.announcementId}`
        );
    }

    fullify(to: string) {
        return `/announcements/${this.$route.params.action}/${this.announcementId}/${to}`;
    }

    setModuleLinks(links: ModuleLink[] = []) {
        this.moduleLinks = links;
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setModuleLinks();

        return next();
    }
}
</script>

<style lang="scss" scoped>
.module-tabs::v-deep {
    .module-tab {
        letter-spacing: normal;

        &:after {
            opacity: 0.1;
            bottom: 0;
            content: '';
            left: 10px;
            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 0;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            border-bottom: 4px solid $secondary-color;
        }

        &:hover {
            &:after {
                opacity: 1;
                left: 0;
                right: 0;
                border-bottom: 4px solid $primary-color;
            }
        }

        &:not(.v-tab--active):hover,
        &:not(.v-tab--active):hover > .v-icon {
            color: $primary-color !important;
        }
    }
}
</style>
